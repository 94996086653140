exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-breads-contentful-loveeatbreads-slug-js": () => import("./../../../src/pages/breads/{ContentfulLoveeatbreads.slug}.js" /* webpackChunkName: "component---src-pages-breads-contentful-loveeatbreads-slug-js" */),
  "component---src-pages-breads-js": () => import("./../../../src/pages/breads.js" /* webpackChunkName: "component---src-pages-breads-js" */),
  "component---src-pages-cakes-js": () => import("./../../../src/pages/cakes.js" /* webpackChunkName: "component---src-pages-cakes-js" */),
  "component---src-pages-dessert-js": () => import("./../../../src/pages/dessert.js" /* webpackChunkName: "component---src-pages-dessert-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-template-type-template-js": () => import("./../../../src/template/type-template.js" /* webpackChunkName: "component---src-template-type-template-js" */)
}

